<!-- Banner Start Here -->
  <div class="headercarousel caoruselDiv hide-mobile">
    <div class="banner-container bg-align-right bg-light-primary-color">
      <div class="page-container" style="margin-top: 0px !important; min-height: 0px !important;">
        <div class="banner-body">
          <div class="banner-left">
            <h2 class="primary-color">The easiest way to buy and sell your new, used, or custom wedding items.</h2>
            <p style="font-weight: bold !important;" class="primary-color">List Your Wedding Items for Free.</p>
            <a wzMustSignIn class="transparent-btn-banner" activity="enroll as a seller." (signedInOutput)="becomeASeller()">
              Start Selling <i class="fas fa-arrow-right"></i>
            </a>
          </div>
          <div class="banner-right">
            <img src="../assets/img/Banner1.png" /> 
          </div>
        </div>
      </div>
    </div>
    <div class="banner-container bg-align-right bg-primary-color">
      <div class="page-container" style="margin-top: 0px !important; min-height: 0px !important;">
        <div class="banner-body">
          <div class="banner-left">
            <h2 style="color: white;">The perfect place to sell your handcrafted wedding items.</h2>
            <p style="color: white; font-weight: bold !important;">Sell with our Wedzee Guarantee.</p>
            <a class="transparent-btn-banner theme-dark" routerLink="new-arrivals"> 
              Shop Now <i class="fas fa-arrow-right"></i>
            </a>
          </div>
          <div class="banner-right">
            <img src="../assets/img/Banner2.png" />
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- Banner End Here -->
  <div class="headercarouselmobile show-mobile" style="margin-left: -1px;">
   <div class="banner-container bg-align-left">
    <div class="page-container bg-light-primary-color" style="margin-top: 0px !important; min-height: 0px !important;">
      <div class="banner-body" style="position: relative;">
        <div class="banner-left" style="padding: 22px 20px;">
          <div>
          <h2 style="font-weight: bold !important;" class="primary-color">Buy & sell all things Wedding.</h2>
          <p style="font-weight: bold !important" class="primary-color">List Your Wedding Items for Free.</p>
          <a wzMustSignIn class="transparent-btn-banner" activity="enroll as a seller.">
            Start Selling <i class="fas fa-arrow-right"></i>
          </a>
          </div>
        </div>
        <div class="banner-right">
          <img src="../assets/img/banner1mobile.png" style="width: 100%;" /> 
        </div>
      </div>
    </div>
   </div>
   <div class="banner-container bg-align-left">
    <div class="page-container  bg-primary-color" style="margin-top: 0px !important; min-height: 0px !important;">
      <div class="banner-body" style="position: relative;">
        <div class="banner-left" style="padding: 22px 20px;">
          <div>
          <h2 style="color: white;font-weight: bold !important">Shop trusted brands & sellers.</h2>
          <p style="color: white;font-weight: bold !important">We have your back on every purchase.</p>
          <a class="transparent-btn-banner theme-dark" routerLink="new-arrivals"> 
            Shop Now <i class="fas fa-arrow-right"></i>
          </a>
          </div>
        </div>
        <div class="banner-right">
          <img src="../assets/img/banner2mobile.png" style="width: 100%;" />
        </div>
      </div>
    </div>
   </div> 
  </div>
 
  <div class="page-container">
    <ng-container *ngIf="homePageData as d">

      <div class="trusticondiv">
        <wz-trust-icon></wz-trust-icon>
      </div>

      <div fxLayout="column" class="shopping-selling-sections">
        <div class="shop-sell-row">
          <div class="shop-sell-col">
            <div class="shop-sell-info">
              <div class="shop-sell-info-left">
                <button wzMustSignIn class="btn btn-large btn-white" (signedInOutput)="becomeASeller()">Start Selling</button> 
              </div>
              <div class="shop-sell-info-right">
                <ul>
                  <li><a href="/sell-on-wedzee"><i class="fas fa-check-circle"></i> How Selling Works</a></li>
                  <li><a href="/sell-on-wedzee"><i class="fas fa-check-circle"></i> List Your Items For Free</a></li>
                  <li><a href="/sell-on-wedzee"><i class="fas fa-check-circle"></i> Sell Directly to Shopping Brides</a></li>
                </ul>
              </div>
            </div>
          </div>
          <div class="shop-sell-col">
            <div class="shop-sell-info gray-theme">
              <div class="shop-sell-info-left">
                <button class="btn btn-large btn-purple" routerLink="/new-arrivals">Start Shopping</button>
              </div>
              <div class="shop-sell-info-right">
                <ul>
                  <li><a href="/how-it-works"><i class="fas fa-check-circle"></i> How Shopping Works</a></li>
                  <li><a href="/categories"><i class="fas fa-check-circle"></i> Shop 80+ Categories</a></li>
                  <li><a href="/wedding-colors"><i class="fas fa-check-circle"></i> Shop by Color</a></li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
  
      <div fxLayout="column">
        <div fxFlexOrder.gt-xs="2" fxFlexOrder.xs="1">
          <ng-container *ngIf="homePageData?.newListings as newList">
            <div ngStyle.xs="margin-top: 15px;" style="margin-top: 20px !important;"
                class="section-header home-title full-width text-left children-inline-block listings-header">
              <h2 class="mat-headline carousel-header">
                Shop New
              </h2>
              <span style="float:right !important;  padding-top: 10px;" class="listings-arrows">
                <img [src]="enablePrevForNewListings == true? '../assets/img/LeftArrow_Active.png':'../assets/img/LeftArrow_Inactive.png'" (click)="slideNewListingsBackward()" style="margin-right: 15px; cursor: pointer !important;" />
                <img [src]="enableNextForNewListings == true? '../../assets/img/RightArrow_Active.png':'../../assets/img/RightArrow_Inactive.png'" (click)="slideNewListingsForward()" style="margin-right: 15px; cursor: pointer !important;" />
              </span>
              <br />
              <mat-hint routerLink="search/new" class="see-more" style="margin-top: 0px; margin-bottom: 20px;"><span class="see-more-span">See more  <img style="vertical-align:middle; margin-left: 5px !important;" src="../../assets/img/HyperlinkArrow.png" /> </span></mat-hint>
            </div>
            <div fxLayout="row wrap"
                fxHide.gt-xs
                fxHide.xs
                style="margin-left: 16px; margin-bottom: -6px; margin-top: -12px; width: 110%"
                class="mobile-featured-container">
              <a *ngFor="let listing of newList; let i = index;"
                [routerLinkAbsolute]="'product/' + listing.id + '/' + listing.name.split(' ').join('-')"
                class="mobile-featured-listing"
                [ngClass]="{ odd: i % 2 > 0 }"
                [ngStyle.xs]="{ display: i > 3 ? 'none' : 'inline-block' }">
                <div>
                  <img [src]="listing.thumbnailImg">
                  <h3>{{listing.name }}</h3>
                  <span>${{listing.price | number:'1.2-2'}}</span>
                </div>
              </a>
            </div>
            <div class="product-carousel-wrapper">
              <wz-product-carousel class="smaller-product-slider" #productCarouselNewListings [products]="newList" [hideNavigation]="true"></wz-product-carousel>
            </div>
          </ng-container>
        </div>


        <div fxFlexOrder.gt-xs="2" fxFlexOrder.xs="1">
          <ng-container *ngIf="homePageData?.customMadeListings as customMadeList">
            <div style="margin-top: 0px !important;padding-top: 0px !important;"
                class="section-header home-title full-width text-left children-inline-block listings-header">
              <h2 class="mat-headline carousel-header">
                Shop Handcrafted
              </h2>
              <span style="float:right !important;  padding-top: 10px;" class="listings-arrows">
                <img [src]="enablePrevForCustomMadeListings == true? '../assets/img/LeftArrow_Active.png':'../assets/img/LeftArrow_Inactive.png'" (click)="slideCustomMadeListingsBackward()" style="margin-right: 15px; cursor: pointer !important;" />
                <img [src]="enableNextForCustomMadeListings == true? '../../assets/img/RightArrow_Active.png':'../../assets/img/RightArrow_Inactive.png'" (click)="slideCustomMadeListingsForward()" style="margin-right: 15px; cursor: pointer !important;" />
              </span>
              <br />
              <mat-hint routerLink="search/handcrafted" class="see-more" style="margin-top: 0px; margin-bottom: 20px;"><span class="see-more-span">See more  <img style="vertical-align:middle; margin-left: 5px !important;" src="../../assets/img/HyperlinkArrow.png" /> </span></mat-hint>
            </div>
            <div fxLayout="row wrap"
                fxHide.gt-xs
                fxHide.xs
                style="margin-left: 16px; margin-bottom: -6px; margin-top: -12px; width: 110%"
                class="mobile-featured-container">
              <a *ngFor="let listing of customMadeList; let i = index;"
                [routerLinkAbsolute]="'product/' + listing.id + '/' + listing.name.split(' ').join('-')"
                class="mobile-featured-listing"
                [ngClass]="{ odd: i % 2 > 0 }"
                [ngStyle.xs]="{ display: i > 3 ? 'none' : 'inline-block' }">
                <div>
                  <img [src]="listing.thumbnailImg">
                  <h3>{{listing.name }}</h3>
                  <span>${{listing.price | number:'1.2-2'}}</span>
                </div>
              </a>
            </div>
            <div class="product-carousel-wrapper">
              <wz-product-carousel class="smaller-product-slider" #productCarouselCustomMadeListings [products]="customMadeList" [hideNavigation]="true"></wz-product-carousel>
            </div>
          </ng-container>
        </div>


        <div fxFlexOrder.gt-xs="2" fxFlexOrder.xs="1">
          <ng-container *ngIf="homePageData?.usedListings as usedList">
            <div style="margin-top: 0px !important;padding-top: 0px !important;"
                class="section-header home-title full-width text-left children-inline-block listings-header">
              <h2 class="mat-headline carousel-header">
                Shop Pre-Loved
              </h2>
              <span style="float:right !important;  padding-top: 10px;" class="listings-arrows">
                <img [src]="enablePrevForPreLovedListings ==true?'../assets/img/LeftArrow_Active.png':'../assets/img/LeftArrow_Inactive.png'" (click)="slidePreLovedListingsBackward()" style="margin-right: 15px; cursor: pointer !important;" />
                <img [src]="enableNextForPreLovedListings ==true?'../../assets/img/RightArrow_Active.png':'../../assets/img/RightArrow_Inactive.png'" (click)="slidePreLovedListingsForward()" style="margin-right: 15px; cursor: pointer !important;" />
              </span>
              <br />
              <mat-hint routerLink="search/used" class="see-more" style="margin-top: 0px; margin-bottom: 20px;"><span class="see-more-span">See more  <img style="vertical-align:middle; margin-left: 5px !important;" src="../../assets/img/HyperlinkArrow.png" /> </span></mat-hint>
            </div>
            <div fxLayout="row wrap"
                fxHide.gt-xs
                fxHide.xs
                style="margin-left: 16px; margin-bottom: -6px; margin-top: -12px; width: 110%"
                class="mobile-featured-container">
              <a *ngFor="let listing of usedList; let i = index;"
                [routerLinkAbsolute]="'product/' + listing.id + '/' + listing.name.split(' ').join('-')"
                class="mobile-featured-listing"
                [ngClass]="{ odd: i % 2 > 0 }"
                [ngStyle.xs]="{ display: i > 3 ? 'none' : 'inline-block' }">
                <div>
                  <img [src]="listing.thumbnailImg">
                  <h3>{{listing.name }}</h3>
                  <span>${{listing.price | number:'1.2-2'}}</span>
                </div>
              </a>
            </div>
            <div class="product-carousel-wrapper">
              <wz-product-carousel class="smaller-product-slider" #productCarouselPreLovedListings [products]="usedList" [hideNavigation]="true"></wz-product-carousel>
            </div>
          </ng-container>
        </div> 

        <div fxFlexOrder.gt-xs="2" fxFlexOrder.xs="1">
          <ng-container *ngIf="d.categories as categories">
            <div style="margin-top: 0px !important;padding-top: 0px !important;"
            class="section-header home-title full-width text-left children-inline-block listings-header">
              <h2 class="mat-headline carousel-header">
                Shop by Category
              </h2>
              <span style="float:right !important;   padding-top: 10px;" class="listings-arrows">
                <img [src]="enablePrevForCategory ==true?'../assets/img/LeftArrow_Active.png':'../assets/img/LeftArrow_Inactive.png'" (click)="slideCategoryCarouselBackward()" style="margin-right: 15px; cursor: pointer !important; " />
                <img [src]="enableNextForCategory ==true?'../../assets/img/RightArrow_Active.png':'../../assets/img/RightArrow_Inactive.png'" (click)="slideCategoryCarouselForward()" style="margin-right: 15px; cursor: pointer !important;" />
              </span>
              <br />
              <mat-hint class="see-more" routerLink="categories">
                <span class="see-more-span">See more  <img style="vertical-align:middle; margin-left: 5px !important;" src="../../assets/img/HyperlinkArrow.png" /> </span>
              </mat-hint>
            </div>
            <div class="product-carousel-wrapper">
              <wz-category-carousel class="smaller-product-slider" #productCarouselCategory [categories]="categories"></wz-category-carousel>
            </div>
          </ng-container>
        </div>

        <div class="image-container" fxFlexOrder.gt-xs="2" fxFlexOrder.xs="1">
          <a href="http://www.psychicsource.com/imc/imc.aspx?pg=407&img=12&imc=26575&tfn=8556439360">
            <img src="assets/winterretlove.jpg" alt="">
          </a>
        </div>

        <div class="wedzee-mailing-container" fxFlexOrder.gt-xs="2" fxFlexOrder.xs="1">
          <wz-subscribe-mailing></wz-subscribe-mailing>
        </div>

        <div fxFlexOrder.gt-xs="2" fxFlexOrder.xs="1">
          <ng-container *ngIf="d.weddingColors as colors">
            <div style="margin-top: 20px !important;"
            class="section-header home-title full-width text-left children-inline-block listings-header">
              <h2 class="mat-headline carousel-header">
                Shop by Color
              </h2>
              <span style="float:right !important;  padding-top: 10px;" class="listings-arrows">
                <img [src]="enablePrevForColour == true?'../assets/img/LeftArrow_Active.png':'../assets/img/LeftArrow_Inactive.png'" (click)="slideColourCarouselBackward()" style="margin-right: 15px; cursor: pointer !important;" />
                <img [src]="enableNextForColour == true?'../../assets/img/RightArrow_Active.png':'../../assets/img/RightArrow_Inactive.png'" (click)="slideColourCarouselForward()" style="margin-right: 15px; cursor: pointer !important;" />
              </span>
              <br />
              <mat-hint class="see-more" routerLink="wedding-colors">
                <span class="see-more-span">See more  <img style="vertical-align:middle; margin-left: 5px !important;" src="../../assets/img/HyperlinkArrow.png" /> </span>
              </mat-hint>
            </div>
            <div class="product-carousel-wrapper">
              <wz-color-carousel class="smaller-product-slider" [colors]="colors"></wz-color-carousel>
            </div>
          </ng-container>
        </div>

        <div fxFlexOrder.gt-xs="2" fxFlexOrder.xs="1">
        <ng-container *ngIf="homePageData?.featuredListings as featured">
          <div style="margin-top: 20px !important;"
              class="section-header home-title full-width text-left children-inline-block listings-header">
            <h2 class="mat-headline carousel-header">
              Popular Listings
            </h2>
            <span style="float:right !important;  padding-top: 10px;" class="listings-arrows">
              <img [src]="enablePrevForFeaturedListings == true?'../assets/img/LeftArrow_Active.png':'../assets/img/LeftArrow_Inactive.png'" (click)="slideFeaturedListingsBackward()" style="margin-right: 15px; cursor: pointer !important;" />
              <img [src]="enableNextForFeaturedListings == true?'../../assets/img/RightArrow_Active.png':'../../assets/img/RightArrow_Inactive.png'" (click)="slideFeaturedListingsForward()" style="margin-right: 15px; cursor: pointer !important;" />
            </span>
            <br />
            <mat-hint routerLink="featured-listings" class="see-more" style="margin-top: 0px; margin-bottom: 20px;"><span class="see-more-span">See more  <img style="vertical-align:middle; margin-left: 5px !important;" src="../../assets/img/HyperlinkArrow.png" /> </span></mat-hint>
          </div>
          <div fxLayout="row wrap"
              fxHide.gt-xs
              fxHide.xs
              style="margin-left: 16px; margin-bottom: -6px; margin-top: -12px; width: 110%"
              class="mobile-featured-container">
            <a *ngFor="let listing of featured; let i = index;"
              [routerLinkAbsolute]="'product/' + listing.id + '/' + listing.name.split(' ').join('-')"
              class="mobile-featured-listing"
              [ngClass]="{ odd: i % 2 > 0 }"
              [ngStyle.xs]="{ display: i > 3 ? 'none' : 'inline-block' }">
              <div>
                <img [src]="listing.thumbnailImg">
                <h3>{{listing.name }}</h3>
                <span>${{listing.price | number:'1.2-2'}}</span>
              </div>
            </a>
          </div>
          <div class="product-carousel-wrapper">
            <wz-product-carousel class="smaller-product-slider" #productCarouselFeaturedListings [products]="featured" [hideNavigation]="true"></wz-product-carousel>
          </div>
        </ng-container>
        </div>
  
        <div fxFlexOrder.gt-xs="3" fxFlexOrder.xs="3">
        <ng-container *ngIf="d.newArrivals as newArrivals">
          <div style="margin-top: 20px!important;padding-top: 0px !important;" *ngIf="newArrivals.length > 0" class="section-header-newarrival home-title full-width text-left children-inline-block  listings-header">
            <h2 class="mat-headline carousel-header" style="font-size: 23px !important; font-weight: bold !important; color:#524F4A !important; ">New Arrivals</h2>
            <span style="float:right !important;  padding-top: 0px;" class="listings-arrows">
              <img [src]="enablePrevForNewArrivals == true?'../assets/img/LeftArrow_Active.png':'../assets/img/LeftArrow_Inactive.png'" (click)="slideNewArrivalsBackward()" style="margin-right: 15px; cursor: pointer !important;" />
              <img [src]="enableNextForNewArrivals == true?'../../assets/img/RightArrow_Active.png':'../../assets/img/RightArrow_Inactive.png'" (click)="slideNewArrivalsForward()" style="margin-right: 15px; cursor: pointer !important;" />
            </span>
            <br />
            <mat-hint routerLink="new-arrivals" class="see-more" style="margin-top: 0px; margin-bottom: 20px;"><span class="see-more-span">See more  <img style="vertical-align:middle; margin-left: 5px !important;" src="../../assets/img/HyperlinkArrow.png" /> </span></mat-hint>
          </div>
          <div class="product-carousel-wrapper">
            <wz-product-carousel class="smaller-product-slider" #productCarouselNewArrivals [products]="newArrivals" [hideNavigation]="true"></wz-product-carousel>
          </div>
        </ng-container>
        </div>

      </div>
      <div class="how-it-works">
        <div class="background"></div>
        <h2 class="wedzeeworkheader">How does Wedzee work?</h2>
        <mat-tab-group style="margin-bottom: 0;" dynamicHeight="true" mat-stretch-tabs animationDuration="200" color="primary" class="about-tab-group">
          <mat-tab style="font-family: 'century_gothicregular' !important;" label="Buy on Wedzee">
            <wz-buy-on-wedzee [withTabs]="true"></wz-buy-on-wedzee>
          </mat-tab>
          <mat-tab style="font-family: 'century_gothicregular' !important;" label="Sell on Wedzee">
            <wz-consumer-sell-on-wedzee [withTabs]="true"></wz-consumer-sell-on-wedzee>
          </mat-tab>
        </mat-tab-group>
      </div>
      <div style="margin-top: 60px;">
        <ng-container>
          <div ngStyle.xs="margin-top: 0px;" style="margin-top: 20px!important;" class="section-header-newarrival home-title full-width text-left children-inline-block  listings-header">
            <h2 class="mat-headline carousel-header" style="font-size: 23px !important; font-weight: bold !important; color:#524F4A !important; ">Recent Reviews</h2>
            <br />
          </div>
          <div class="review-carousel-wrapper">
            <wz-review-carousel></wz-review-carousel>
          </div>
        </ng-container>
      </div>
      <div fxFlexOrder.gt-xs="4" fxFlexOrder.xs="4">
        <ng-container>
          <div id="divInThePress" class="section-header home-title full-width text-left children-inline-block">
            <h2 class="mat-headline carousel-header" style="font-size: 23px !important; font-weight: bold !important; color:#524F4A !important; margin-bottom: 20px;">Featured In</h2>
            <div class="in-the-press" style="display:flex;flex-wrap: wrap;">
              <div class="in-the-press-image-container">
                <span class="helper"></span>
                <a href="https://www.stylemepretty.com/2021/07/24/find-everything-you-need-for-your-wedding-on-wedzee-the-online-marketplace-exclusively-for-buying-and-selling-wedding-items/" target="_new"><img src="/assets/images/style-me-pretty.png" class="in-the-press-images" /></a>
              </div>
              <div class="in-the-press-image-container">
                <span class="helper"></span>
                <a href="https://www.floridaweddingpodcast.com/episodes/episode-14-jennifer-burns" target="_new"><img src="/assets/images/florida-wedding-podcast.jpg" class="in-the-press-images" /></a>
              </div>
              <div class="in-the-press-image-container">
                <span class="helper"></span>
                <a href="https://thebudgetsavvybride.com/wedzee-wedding-marketplace/" target="_new"><img src="/assets/images/budget-savy-bride.png" class="in-the-press-images" /></a>
              </div>
              <div class="in-the-press-image-container">
                <span class="helper"></span>
                <a href="https://www.bridalguide.com/planning/etiquette-advice/tips-tricks/wedzee" target="_new">
                  <img src="/assets/images/bridal-guide.png" class="in-the-press-images" />
                </a>
              </div>
              <div class="in-the-press-image-container">
                <span class="helper"></span>
                <a href="https://emmalinebride.com/where-to/sell-used-wedding-stuff/" target="_new">
                  <img src="/assets/images/emmaline-bride.png" class="in-the-press-images" />
                </a>
              </div>
              <div class="in-the-press-image-container">
                <span class="helper"></span>
                <a href="https://bridalmusings.com/176606/wedzee-wedding-resale-platform/" target="_new"><img src="/assets/images/bridal-musings.png" class="in-the-press-images" /></a>
              </div>
            </div>
          </div>
        </ng-container>
        </div>
    </ng-container>
  </div>
